import * as React from 'react';
import { ComponentType } from 'react';

import AppContext from '../layouts/AppContext';

/**
 * Wraps a component within the app context
 * @param Component
 */
// eslint-disable-next-line func-names
export const withAppContext = <P extends object>(Component: ComponentType<P>) => function (props) {
  // @ts-ignore
  return <AppContext><Component {...props as P} /></AppContext>;
};
