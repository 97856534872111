import React from 'react';
import Card from 'react-bootstrap/esm/Card';
import { useTranslation } from 'react-i18next';

import { withAppContext } from '@/application/components/WithAppContext';
import DateTimeLocalized from '@/shared/components/DateTimeLocalized';
import LoadingIndicator from '@/shared/components/LoadingIndicator';
import { useAxios } from '@/shared/hooks/useApi';

type SimilarArticle = {
  id: number;
  title: string;
  published_at: string;
};

function SimilarArticlesContent({
  articleId,
}: {
  articleId: number;
}) {
  const { t } = useTranslation();

  const [{ data, loading, error }] = useAxios({ url: `/api/articles/${articleId}/similar_articles` });
  const similarArticles: SimilarArticle[] | null = data || null;

  let content = null;
  if (loading) {
    content = <LoadingIndicator />;
  } else if (error) {
    content = <p>{t('articles.similar.fetching_error')}</p>;
  } else if (similarArticles === null) {
    content = <p>{t('articles.similar.no_semantic_analysis')}</p>;
  } else if (similarArticles.length === 0) {
    content = <p>{t('articles.similar.no_articles')}</p>;
  } else {
    content = (
      <div>
        {similarArticles.map((similarArticle) => (
          <Card className="mt-4" key={similarArticle.id}>
            <a href={`/articles/${similarArticle.id}`}>
              <Card.Body>
                <Card.Title>{similarArticle.title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  <DateTimeLocalized format="LLL">
                    {similarArticle.published_at}
                  </DateTimeLocalized>
                </Card.Subtitle>
              </Card.Body>
            </a>
          </Card>
        ))}
      </div>
    );
  }

  return (
    <>
      <h2>{t('articles.similar.title.plural')}</h2>
      {content}
    </>
  );
}

const SimilarArticles = withAppContext(SimilarArticlesContent);

export default SimilarArticles;
